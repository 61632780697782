<template>
  <div class="elv-valuation-raw-data-content-panel">
    <ListScreening
      v-if="props.showScreening"
      :filterType="props.tableType"
      :isShowSortButton="props.isShowSortButton"
      :isShowFilterButton="props.isShowFilterButton"
      @onChangeSort="onChangeSort"
      @onOpenFilterOverlay="onOpenFilterOverlay"
    />
    <div
      class="elv-valuation-raw-data-content-panel-table-wrapper"
      :class="{ 'elv-valuation-raw-data-content-panel-table-wrapper-hide-screening': !props.showScreening }"
    >
      <CustomTable
        ref="tableListRef"
        class="elv-valuation-raw-data-content-panel-table"
        border
        stripe
        :hideSinglePage="props.hideSinglePage"
        :tableData="props.tableData.list"
        :tableHeader="props.headerList"
        :paginationData="props.pageParams"
        :tableCountNumber="props.tableData.totalCount"
        :tableHeight="currentTableHeight"
        :tableLoading="tableLoading"
        @onChangePage="onChangePage"
        @onChangePageSize="onChangePageSize"
      >
        <template #origin="{ data }">
          <p>
            {{ t(currentRawDataTableInfo(data).origin) }}
          </p>
        </template>
        <template #account="{ data }">
          <IconWithTitleCell
            class="elv-valuation-raw-data-content-panel-table-account"
            :icon="data?.entityAccount?.platform?.logo || ''"
            :title="data?.entityAccount?.name || '-'"
          />
        </template>
        <template #dateTime="{ data }">
          <VerticalTextCell align="left" :up-text="currentRawDataTableInfo(data).datetime" :down-text="''" />
        </template>
        <template #referenceNo="{ data }">
          <VerticalTextCell align="left" :up-text="data?.referenceNo || '-'" :down-text="''" />
        </template>
        <template #type="{ data }">
          <VerticalTextCell
            align="left"
            up-font-family="Plus Jakarta Sans"
            :up-text="currentRawDataTableInfo(data).type"
            :down-text="''"
          />
        </template>
        <template #symbol="{ data }">
          <VerticalTextCell
            align="left"
            upFontFamily="Plus Jakarta Sans"
            :upText="data.asset.derivative?.name || '-'"
            :downText="''"
            upFontSize="12px"
            :upWeight="500"
          />
        </template>
        <template v-if="props.tableType === valuationFilterSaveType.VALIDATOR_RAW_DATA" #asset="{ data }">
          <IconWithTitleCell
            :icon="''"
            :title="rawDataAssetInfo(data).title || '-'"
            titleFontFamily="Plus Jakarta Sans"
            :titleWeight="500"
            titleSize="12px"
          />
        </template>
        <template v-if="props.tableType === valuationFilterSaveType.VALIDATOR_RAW_DATA" #amount="{ data }">
          <VerticalTextCell
            align="right"
            :upText="typeof data?.amount === 'string' ? formatNumber(Number(data?.amount), 2) : '-'"
            :upDesc="data?.amount || ''"
            :upWeight="500"
            :downText="''"
          />
        </template>
        <template v-if="props.tableType === valuationFilterSaveType.FUTURE" #positionSide="{ data }">
          <IconWithTitleCell
            :svgName="positionSideInfo(data).svgName || ''"
            :title="t(positionSideInfo(data).label) || '-'"
            :titleWeight="400"
          />
        </template>
        <template v-if="props.tableType === valuationFilterSaveType.FUTURE" #marginAsset="{ data }">
          <IconWithTitleCell
            :icon="rawDataAssetInfo(data).icon || ''"
            :title="rawDataAssetInfo(data).title || ''"
            :titleWeight="500"
          />
        </template>
        <template v-if="props.tableType === valuationFilterSaveType.OPTIONS" #quoteAsset="{ data }">
          <IconWithTitleCell
            :icon="rawDataAssetInfo(data).icon || ''"
            :title="rawDataAssetInfo(data).title || ''"
            :titleWeight="500"
          />
        </template>
        <template #pAndL="{ data }">
          <VerticalTextCell
            align="right"
            :upText="typeof data?.amount === 'string' ? formatNumber(Number(data?.amount), 2) : '0'"
            :upDesc="data?.amount || ''"
            :upWeight="500"
            :downText="''"
          />
        </template>
        <template #space> </template>
      </CustomTable>
    </div>
  </div>
</template>

<script setup lang="ts">
import dayjs from 'dayjs'
import utc from 'dayjs/plugin/utc'
import { find, isEmpty } from 'lodash-es'
import { formatNumber } from '@/lib/utils'
import timezone from 'dayjs/plugin/timezone'
import ListScreening from './ListScreening.vue'
import { FutureFactItemType } from '#/ValuationTypes'
import { useEntityStore } from '@/stores/modules/entity'
import { useGlobalStore } from '@/stores/modules/global'
import { gainLossType, positionOptions } from '@/config'
import CustomTable from '../../../components/CustomTable.vue'
import VerticalTextCell from '@/components/Base/Table/Cell/VerticalTextCell.vue'
import IconWithTitleCell from '@/components/Base/Table/Cell/IconWithTitleCell.vue'
import { adjustmentOriginOptions, validatorFactType, valuationFilterSaveType } from '@/config/valuation'

const props = defineProps({
  tableType: {
    type: String,
    default: valuationFilterSaveType.FUTURE
  },
  headerList: {
    type: Array as PropType<any[]>,
    default: () => []
  },
  tableData: {
    type: Object as PropType<any>,
    default: () => {
      return {
        totalCount: 0,
        list: [],
        filteredCount: 0
      }
    }
  },
  pageParams: {
    type: Object as PropType<any>,
    default: () => {
      return {
        page: 1,
        limit: 20,
        sortField: 'datetime',
        sortDirection: 'DESC'
      }
    }
  },
  tableLoading: {
    type: Boolean,
    default: false
  },
  tableHeight: {
    type: String,
    default: ''
  },
  hideSinglePage: {
    type: Boolean,
    default: false
  },
  showScreening: {
    type: Boolean,
    default: true
  },
  isShowSortButton: {
    type: Boolean,
    default: false
  },
  isShowFilterButton: {
    type: Boolean,
    default: false
  }
})

const emit = defineEmits(['onChangePage', 'onChangePageSize', 'onChangeSort', 'onOpenFilterOverlay'])

const { t } = useI18n()
const entityStore = useEntityStore()
const globalStore = useGlobalStore()

dayjs.extend(utc)
dayjs.extend(timezone)

const currentTableHeight = computed(() => {
  if (props.tableHeight) return props.tableHeight
  const updateProjectTipHeight = globalStore.freeProjectUpdateInfo(entityStore.entityDetail?.projectId) ? '28px' : '0px'
  return `calc(100vh - 341px - ${updateProjectTipHeight})`
})

const currentRawDataTableInfo = computed(() => {
  return (row: FutureFactItemType) => {
    const rowData = { origin: '', datetime: '', type: '-' }
    rowData.origin = adjustmentOriginOptions.find((item) => item.value === row.origin)?.label || ''
    rowData.datetime = dayjs(row?.datetime)
      .tz(entityStore.entityDetail?.timezone ?? 'UTC')
      .format('YYYY/MM/DD HH:mm')
    if (props.tableType === valuationFilterSaveType.VALIDATOR_RAW_DATA) {
      rowData.type = find(validatorFactType, { value: row.type })?.label || ''
    } else {
      rowData.type = gainLossType.find((item) => item.value === row.type)?.label || ''
    }
    if (rowData.type?.length) {
      rowData.type = t(rowData.type).split(' ').join('-')
    } else {
      rowData.type = '-'
    }
    return rowData
  }
})

const rawDataAssetInfo = computed(() => {
  return (data: any) => {
    const assetData = { icon: '', title: '-' }
    if (isEmpty(data.asset?.underlyingCurrency) && isEmpty(data.asset?.derivative)) {
      return assetData
    }
    if (isEmpty(data.asset?.underlyingCurrency)) {
      assetData.title = data.asset?.derivative?.marginAssetCurrency?.showSymbol || '-'
      assetData.icon = data.asset?.derivative?.marginAssetCurrency?.logo || ''
    } else {
      assetData.title = data.asset?.underlyingCurrency?.showSymbol || '-'
      assetData.icon = data.asset?.underlyingCurrency?.logo || ''
    }
    return assetData
  }
})

const positionSideInfo = computed(() => {
  return (data: any) => {
    return positionOptions.find((item) => item.value === data.positionSide) || { label: '', svgName: '' }
  }
})

/**
 * RawData分页器改变页码
 * @param page 页码
 */
const onChangePage = (page: number) => {
  emit('onChangePage', page)
}

/**
 * RawData分页器改变每页条数
 * @param pageSize 每页条数
 */
const onChangePageSize = (pageSize: number) => {
  emit('onChangePageSize', pageSize)
}

/**
 * 列表排序类型 时间排序
 * @param data 排序字段 DESC/ASC
 */
const onChangeSort = (data: string) => {
  emit('onChangeSort', data)
}

/**
 * 打开筛选RawData弹窗
 */
const onOpenFilterOverlay = () => {
  emit('onOpenFilterOverlay')
}
</script>

<style lang="scss">
.elv-valuation-raw-data-content-panel {
  width: 100%;
  height: 100%;
  position: relative;

  .elv-valuation-raw-data-content-panel-table-wrapper {
    width: 100%;
    height: calc(100% - 47px);
    position: relative;

    &-hide-screening {
      margin-top: 16px;

      .elv-component-custom-table-panel-content-container {
        border-left: 1px solid #edf0f3;
      }
    }

    .elv-valuation-raw-data-content-panel-table {
      border-bottom: none;

      .elv-component-custom-table-row__cell:last-child {
        border-right: 1px solid #edf0f3;
      }

      .elv-component-custom-table-row__cell .cell {
        font-size: 12px;
        font-weight: 500;

        .elv-base-cell-icon-title {
          padding: 12px 0px;
        }

        .elv-valuation-raw-data-content-panel-table-account {
          img {
            width: 14px;
            height: 14px;
            margin-right: 4px;
          }

          div {
            font-weight: 500 !important;
            font-size: 12px !important;
          }
        }

        .elv-valuation-calculation-content-panel-table-symbol {
          font-size: 13px;
          font-weight: 600;
        }
      }

      .elv-component-custom-table-header__cell .cell {
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
      }
    }

    .elv-component-custom-list-pagination {
      border-top: 1px solid #edf0f3;
    }
  }
}
</style>
